@import url(https://rsms.me/inter/inter.css);
html {
    font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

* {
    box-sizing: border-box;
}

:root {
    --color-contrast: #2a9d8f;
    --color-font-contrast: #fcfcfc;
    --color-contrast-second: #577590;
    --color-background: #fcfcfc;
    --color-font: #232323;
    --color-dark: #030303;
    --color-success: #43aa8b;
    --color-error: #f94144;
    --border-radius: 10px;
    --margin-basic: 10px;
}

